import 'styles/default'
import 'styles/homepage'
const CODE_LENGTH = 6

function initializeAccessCodeForm() {
  const accessCodeField = document.getElementById('access_code') as HTMLInputElement
  const connectBtn = document.getElementById('access_code_connect_button') as HTMLInputElement

  accessCodeField.addEventListener('input', (event: Event) => {
    const target = event.target as HTMLInputElement
    const { value } = target

    if (value.length >= CODE_LENGTH) {
      target.value = value.trim().slice(0, CODE_LENGTH)
      connectBtn.disabled = false
      connectBtn.focus()
    } else {
      connectBtn.disabled = true
    }
  })

  accessCodeField?.focus()
  connectBtn.disabled = true
}

window.addEventListener('DOMContentLoaded', () => {
  initializeAccessCodeForm()
})
